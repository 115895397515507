<template>
  <div class="page bg-white">
    <div
      v-if="!state.userCanLogin"
      class="page d-flex justify-content-center align-content-center"
    >
      <van-loading
        v-if="!state.wechatAuthError"
        class="justify-content-center align-content-center"
        color="#1989fa"
        size="36"
        vertical
      >
        加载中
      </van-loading>
      <van-empty v-else image="error" :description="state.wechatAuthError">
        <van-button type="primary" plain @click="redoWechatAuth"
          >重新授权</van-button
        >
      </van-empty>
    </div>
    <div v-else>
      <van-image
        width="100%"
        fit="cover"
        :height="config.appBannerHeight"
        :src="
          config.appBanner
            ? config.appBanner
            : require('@/assets/images/banner.png')
        "
      />
      <van-cell-group>
        <template #title>
          <img
            v-if="state.userinfo.headimgurl"
            :src="state.userinfo.headimgurl"
            alt="头像"
            width="20"
            height="20"
            class="rounded-circle"
          />
          {{ state.userinfo.nickname }}
        </template>
        <van-field
          v-model.trim="state.phone"
          type="tel"
          maxlength="11"
          label-width="4em"
          label="手机号"
          placeholder="请输入手机号码"
        />
        <van-field
          v-model.number="state.smscode"
          type="number"
          maxlength="4"
          label-width="4em"
          label="验证码"
          placeholder="请输入4位验证码"
        >
          <template #button>
            <van-button
              v-if="state.remainingTime > 0"
              size="small"
              disabled
              plain
              type="primary"
              >{{ state.smsButtonText }}</van-button
            >
            <van-button
              v-else
              size="small"
              plain
              type="primary"
              @click="sendCode"
              >{{ state.smsButtonText }}</van-button
            >
          </template>
        </van-field>
        <van-cell>
          <template #value>
            <van-checkbox v-model="state.argee"
              >同意本平台用户协议</van-checkbox
            >
          </template>
        </van-cell>
      </van-cell-group>

      <van-row class="py-4">
        <van-col span="8"></van-col>
        <van-col span="8">
          <van-button type="primary" block @click="login">登录</van-button>
          <van-button
            type="default"
            block
            class="mt-2"
            @click="$router.push({ name: 'Agreement' })"
            >查看用户协议</van-button
          >
        </van-col>
        <van-col span="8"></van-col>
      </van-row>
    </div>
    <!-- end.else -->
  </div>
</template>

<script>
import {
  Row,
  Col,
  Field,
  Checkbox,
  Toast,
  Notify,
  Image as VanImage,
} from "vant";
import { useStore } from "vuex";
import { onMounted, onUnmounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sendSmsCode } from "@/api/sms.service";
import { getAuthUrl, wechatAuth } from "@/api/wechat.service";
import { LOGIN, SET_AUTH, VERIFY_AGENT } from "@/store/modules/auth";
import { isWechat } from "@/utils/util";
import config from "@/config/config.local";

export default {
  name: "Login",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [VanImage.name]: VanImage,
  },

  setup() {
    let timer = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userCanLogin: false,
      wechatAuthError: null,
      userinfo: {},
      phone: "",
      smscode: "",
      argee: true,
      smsButtonText: "发送验证码",
      remainingTime: 0,
    });

    const redoWechatAuth = () => {
      const url = location.href.split("/login")[0];
      window.location.href = `${url}/login?reauth`;
    };

    const checkPhone = () => {
      if (!state.phone) {
        Toast({ message: "请输入手机号码", position: "bottom" });
        return false;
      }
      if (state.phone.length != 11) {
        Toast({ message: "手机号码不正确", position: "bottom" });
        return false;
      }
      return true;
    };

    const checkCode = () => {
      if (!state.smscode) {
        Toast({ message: "请输入验证码", position: "bottom" });
        return false;
      }
      if (state.smscode.toString().length != 4) {
        Toast({ message: "验证码不正确", position: "bottom" });
        return false;
      }
      return true;
    };

    const tickTick = () => {
      // 定时器 90 秒后可重发短信验证码
      state.remainingTime = 90;
      timer = setInterval(() => {
        if (state.remainingTime == 0) {
          state.smsButtonText = "发送验证码";
          clearInterval(timer);
        } else {
          state.remainingTime--;
          state.smsButtonText = `${state.remainingTime}秒后可重发`;
        }
      }, 1000);
    };

    const sendCode = async () => {
      if (!checkPhone()) {
        return;
      }
      try {
        const resp = await sendSmsCode(state.phone);
        if (resp.result.BizId) {
          tickTick();
          Toast({ message: "已发送", position: "bottom" });
          state.smscode = "";
        } else {
          Notify({ type: "warning", message: resp.msg });
        }
      } catch (error) {
        Notify({ type: "warning", message: "发送验证码失败" });
      }
    };

    const login = async () => {
      if (!checkPhone()) {
        return;
      }
      if (!checkCode()) {
        return;
      }
      if (!state.argee) {
        Toast({ message: "请勾选同意用户协议", position: "bottom" });
        return;
      }
      store
        .dispatch(LOGIN, {
          phone: state.phone,
          code: state.smscode,
        })
        .then(() => {
          Toast({ message: "欢迎登录", position: "bottom", duration: 3500 });
          const redirect = route.query.redirect || "/";
          // 登录成功自动尝试获取一次代理信息, 之后再进行跳转
          store
            .dispatch(VERIFY_AGENT)
            .then(() => {
              router.push(decodeURIComponent(redirect));
            })
            .catch(() => {
              router.push(decodeURIComponent(redirect));
            });
        })
        .catch((error) => {
          Notify({ type: "danger", message: error });
        });
    };

    // 先进行微信登录授权, 然后再使用手机验证码登录
    const preauth = async (queryCode, queryState) => {
      try {
        const { result } = await wechatAuth(queryCode, queryState);
        const forceSmsLogin = store.getters.forceSmsLogin;
        if (result.session_id && !forceSmsLogin) {
          store.commit(SET_AUTH, result);
          Toast({
            message: "欢迎登录",
            position: "bottom",
            duration: 3500,
          });
          const redirect = route.query.redirect || "/";
          // 登录成功自动尝试获取一次代理信息, 之后再进行跳转
          store
            .dispatch(VERIFY_AGENT)
            .then(() => {
              router.push(decodeURIComponent(redirect));
            })
            .catch(() => {
              router.push(decodeURIComponent(redirect));
            });
        } else {
          state.userCanLogin = true;
          state.wechatAuthError = null;
          state.userinfo = result; // 备用, 可用于展示用户头像和昵称
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        state.wechatAuthError = msg ? msg : "获取微信授权失败";
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      if (isWechat()) {
        const { code, state } = route.query;
        if (code && state) {
          preauth(code, state);
        } else {
          setTimeout(() => {
            const callback_url = location.href.split("#")[0];
            const linker = callback_url.indexOf("?") == -1 ? "?" : "&";
            const redirect_url = getAuthUrl(
              `${callback_url}${linker}_t=${new Date().getTime()}`
            );
            console.log(redirect_url);
            window.location.href = redirect_url;
          }, 1200);
        }
      } else {
        state.userCanLogin = true;
        state.userinfo = {
          nickname: "用户登录",
          headimgurl: "",
        };
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return { config, state, redoWechatAuth, sendCode, login };
  },
};
</script>
