import ApiService from "./api.service";

export function sendSmsCode(phone) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/sms/send", {
      phone,
      type: 0,
    })
      .then(({ data }) => {
        // data 参数由 response 入参解构而来, 直接返回后端数据给调用者使用
        resolve(data);
      })
      .catch(({ response }) => {
        // response 参数由 error 入参解构而来, 返回  对象, 需要使用到 http 状态码
        reject(response);
      });
  });
}
